/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState, useContext } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { seoData } from "../locales"
import { useStaticQuery, graphql } from "gatsby"
import { LangStateContext } from "../context/langContext"

const isBrowser = typeof window !== "undefined" && window

const SEO = ({
  lang = 'en',
  meta = [],
  page = 'home',
  location,
  styleSheet = "",
  title_custom
}) => {
  lang = useContext(LangStateContext);

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteName
            keywords
            branchName
          }
        }
      }
    `
  )

  const defaultPage = 'home';
  page = page?.toLowerCase();

  const curSeoData = seoData?.[lang]?.[page] || seoData?.[lang]?.[defaultPage];
  const title = title_custom || curSeoData?.title ||  site.siteMetadata.title;
  const description = curSeoData?.description || site.siteMetadata.description;
  const keywords = curSeoData?.keywords || site.siteMetadata.keywords;
  const image = curSeoData?.image || `${process.env.OSS_URL}images/seo/default.jpg`;
  console.log(`%c🚀 ${title} version: ${site.siteMetadata.branchName}`, "color: white; background: blue; font-size: 16px; padding: 5px; border-radius: 5px;")
  
  const isIE =
    isBrowser &&
      "-ms-scroll-limit" in window.document.documentElement.style &&
      "-ms-ime-align" in window.document.documentElement.style
      ? "true"
      : "false"

  return (
    <Helmet
      htmlAttributes={{
        lang,
        page,
        isIE,
      }}
      title={title}
      titleTemplate={`%s`}
      // 原来合成的SEO title格式 以上完全按传递的title来定义
      // titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        { lang },
        {
          name: `keywords`,
          content: keywords,
        },
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: location ? location : "https://www.xreal.com/",
        },
        {
          property: `og:site_name`,
          content: site.siteMetadata.siteName,
        },
        {
          property: `fb:app_id`,
          content: "797308034041960",
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: "@XREAL",
        },
        {
          name: `twitter:image`,
          content: image,
        },
        {
          name: `twitter:site`,
          content: "@XREAL",
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          name: "google-site-verification",
          content: "-pFMwKhaEu_alNp4d374AYv7OK4Q-Rf-YebTKlSNRqU",
        },
      ].concat(meta)}
    >
      <style>{styleSheet}</style>
    </Helmet>
  )
}

export default SEO
